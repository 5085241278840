import "../../../assets/styles/views/widget/form-wizard/main.scss";
import { LitElement, html, css, TemplateResult } from 'lit';
import { customElement, property, query } from 'lit/decorators.js'
import { AkwaabaFormWizardItem } from './form-item';
import "@material/mwc-icon-button";
import { IconButton } from '@material/mwc-icon-button';
import "@@addons/widgets/alert";
import { hiddenField_I, submitForm_I } from "@@addons/interfaces/form/form-wizard";

// submitForm(e: PointerEvent)
@customElement('akwaaba-form-wizard')
export class AkwaabaFormWizard extends LitElement {
  constructor() { super(); }

  @query('[go-previous-page="btn"]')
  private goPreviousPageBtn: IconButton;

  @query('[go-next-page="btn"]')
  private goNextPageBtn: IconButton;

  @property({ type: Array })
  public formPages?: AkwaabaFormWizardItem[];

  @property({ type: String })
  public formName?: string = "Loading...";

  @property({ type: Number })
  private currentPage: number = 0;

  @property({ type: String })
  public makeGeneralPosts?: string;

  public submitForm?: submitForm_I;

  @property({ type: Array })
  public hiddenFields?: hiddenField_I[];

  @property({ type: Boolean })
  private singlePageCalled?: boolean = false;

  // submitForm_(e: PointerEvent): Promise<void>


  connectedCallback() {
    super.connectedCallback();

    setInterval(() => {
      if (this.formPages.length > 0) {
        if (this.singlePageCalled === false) {
          this.singlePage();
          this.singlePageCalled = true;
        }
      }
    }, 1000)
  }

  disconnectedCallback() { }
  static styles = [
    css`
   :host { display: block; }
  `
  ];

  render() {
    // ${this.emptyPage}
    return html`
      <div class="content">
        <header class="header">${this.formName}</header>
        <main form-content-here>
          <form enctype="multipart/form-data" method="post" action="#" class="form"
            make-general-posts="${this.makeGeneralPosts}">
            ${this.displayFormPages}
            ${this.genHiddenFields}
          </form>
        </main>
        <footer class="footer">
          <mwc-icon-button disabled go-previous-page="btn" class="mr-2 warning" icon="chevron_left"
            @click="${this.goToPreviousPage}">
          </mwc-icon-button>
          <mwc-icon-button go-next-page="btn" class="info" icon="chevron_right" @click="${this.goToNextPage}">
          </mwc-icon-button>
        </footer>
      </div>
    `;
  }

  firstUpdated() { }
  
  private singlePage() {
    console.log({"this.formPages": this.formPages});
    
    if (this.goNextPageBtn !== null) {
      if (this.formPages.length === 1) {
        this.goNextPageBtn.setAttribute('icon', 'send');
        this.goNextPageBtn.setAttribute('class', 'success');
        this.goNextPageBtn.addEventListener('click', this.submitForm);
      }
    }
  }
  
  private get emptyPage() {
    let empty = false;
    if (this.formName !== "Loading...") {
      for (let i = 0; i < this.hiddenFields.length; i++) {
        const field = this.hiddenFields[i];
        if (field.name === "clientId" || field.name === "questionnaire") {
          console.log({"field.value": field.value});
          
          if (field.value === 'undefined') {
            console.log({"field.value": field.value});
            empty = true;
          }
        }
      }
      if (empty === true) {
        return html`
          <alert-card warning .header="${html`Sorry`}" .content="${html`No Questions Founds`}"></alert-card>
        `;
      }
    }
    return ``;
  }

  private get genHiddenFields() {
    return this.hiddenFields.map(field => {
      return html`<input type="hidden" name="${field.name}" value="${field.value}">`;
    });
  }

  private get displayFormPages(): TemplateResult {
    let display = html``;
    this.formPages.forEach((item, i) => {
      let isActive = "false";
      if (i === 0) {
        isActive = "true";
      }
      const _item = html`
        <div active-page="${isActive}" page-index="${i}">
          ${item}
        </div>`;
      display = html`${display} ${_item}`;
    });

    return display;
  }

  private async goToPreviousPage(event: any) {
    if (this.currentPage > 0) {
      this.goNextPageBtn.removeAttribute('disabled');
      this.goNextPageBtn.setAttribute('class', 'info');
      this.goNextPageBtn.setAttribute('icon', 'chevron_right');
      // console.log({"this.currentPage-Previous-1": this.currentPage});

      this.currentPage -= 1;
      this.setPageStatus(this.currentPage, "true");
      if (this.currentPage === 0) {
        this.goPreviousPageBtn.setAttribute('disabled', 'true');
      }
      // console.log({"this.currentPage-Previous-2": this.currentPage});


    } else {
      this.goPreviousPageBtn.setAttribute('disabled', 'true');
      this.goNextPageBtn.removeAttribute('disabled');
    }
  }

  private async goToNextPage(event: any) {
    const _currentPage = this.formPages.length - 1;
    if (this.currentPage < _currentPage) {
      this.goPreviousPageBtn.removeAttribute('disabled');
      // console.log({ "this.currentPage-Next-1": this.currentPage });

      this.currentPage += 1;
      await this.formAction(() => {
        this.setPageStatus(this.currentPage, "true");
        if (this.currentPage === _currentPage) {
          // this.goNextPageBtn.setAttribute('disabled', 'true');
          this.goNextPageBtn.setAttribute('icon', 'send');
          this.goNextPageBtn.setAttribute('class', 'success');

        }
        console.log({"this.currentPage": this.currentPage, _currentPage});
        
        if (this.currentPage === _currentPage) {
          this.goNextPageBtn.addEventListener('click', this.submitForm);
        }
        // console.log({"this.currentPage-Next-2": this.currentPage});
      });
    } else if (this.currentPage >= _currentPage) {
      console.log({
        "this.currentPage": this.currentPage,
        "_currentPage": _currentPage
      });
    } else {
      this.goPreviousPageBtn.removeAttribute('disabled');
      this.goNextPageBtn.setAttribute('disabled', 'true');
    }
  }

  private setPageStatus(page: number, status: string) {
    const pageElement = this.querySelector(`[page-index="${page}"]`);

    const pages = this.querySelectorAll(`[page-index]`);
    pages.forEach((element) => {
      element.setAttribute('active-page', 'false');
    });

    pageElement.setAttribute('active-page', status);
  }

  private async formAction(callback: () => void) {
    const form = this.formPages[this.currentPage - 1];
    // console.log({ id: this.currentPage, form });
    if (form.action !== false) {
      // console.log({"form.action": await form.action(jk)});
      form.action((done) => {
        console.log({ done });

        if (done) {
          callback();
        }
      });
    } else {
      callback();

    }
  }

  createRenderRoot() {
    return this;
  }
}

