import { AppDashboardReport_I } from "@@addons/interfaces/dashboard";
import { AppDatabase } from "..";

export class AppDashboardReportStore extends AppDatabase<AppDashboardReport_I> {
  private db = this;
  public constructor() {
    super();

    this.storeTable = this.db.DashboardReportDB__;
  }

  /**
   * formatRawData
   */
  public formatRawData(rawData: any) {
    // console.log({ rawData });
    const proccessed: AppDashboardReport_I = {
      id: Number(rawData['id']),
      url: String(rawData['url'] === 'null'? '': rawData['url']),
      icon: String(rawData['icon']),
      title: String(rawData['title']),
      description: String(rawData['description']),
    };
    return proccessed;
  }

}