import * as _ from 'lodash';
import * as _wcjs from '@webcomponents/webcomponentsjs';
import { CONSTANTS } from "../../addons/constants";
import '../../addons/firebase';
if (CONSTANTS.APP_SETTINGS.production) {
  window['service-worker-url-path'] = CONSTANTS.URLS.PUBLIC_PAGE_BASE_URL + 'service-worker.js';
} else {
  window['service-worker-url-path'] = CONSTANTS.URLS.BASE_URL + 'akwaaba-forms-public/service-worker.js';
}
import '../../addons/service-worker/sw_register';
// import { SaveIfOnline } from '../addons/database/dixie/offline_posts/save_if_online';

// new SaveIfOnline();