import "../../../assets/styles/views/homepage/main.scss";
import '../../../pages/public/wireframe';
import '@material/mwc-circular-progress';
import { MobxLitElement } from '@adobe/lit-mobx';
import { html, css, TemplateResult, nothing } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { wireframeProvider } from '@@addons/mobx-providers/wireframe';
import { AppMessenger } from "@@addons/classes/messenger_channel";
import "@@addons/widgets/form-wizzard";
import { PublicLoginStore_I } from "@@addons/interfaces/database/login/public";
import { AppDashboardReport_I } from "@@addons/interfaces/dashboard";
import { GetUserPageFx } from "@@addons/functions/views/wireframe/get_user_page";
import { loginProfileProvider } from "@@addons/mobx-providers/login";
import { CONSTANTS } from "@@addons/constants";
import { GetDbLoadedAllFx } from "@@addons/functions/views/wireframe/db_loaded/all";
import { AppDashboardReportStore } from "@@addons/database/dixie/dashboard";
import { GET_MemberDashboardReport } from "@@addons/network/dashboard/member-report";
import "@@addons/widgets/alert";
import "@@addons/widgets/alert/clickable";
import '@material/mwc-button';
import '@material/mwc-textfield';
import Swiper, { Navigation, Pagination } from 'swiper';
import { Button } from "@material/mwc-button";
// import Swiper and modules styles
// import 'swiper/swiper-bundle.css';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';

@customElement("akwaaba-forms-public-homepage")
export class AkwaabaFormsPublicHomepage extends MobxLitElement {
  constructor() { super(); }
  
  @property({ type: Number })
  public pageId: number = 0;

  private _userProfile: PublicLoginStore_I = {};

  public set userProfile(value: PublicLoginStore_I) {
    this._userProfile = value;
  }

  public get userProfile(): PublicLoginStore_I {
    return this._userProfile;
  }

  @property({ type: Object })
  private _dashboardReportResponse: any;

  @property({ type: Array })
  private _dashboardReports?: AppDashboardReport_I[] = [];

  @property({ type: Number })
  private _memberId: number = 0;

  @property({ type: Number })
  private _accountType: number = 0;

  private swiperSelector: HTMLElement;

  @property({ type: Boolean })
  private swiperSelectorShow: boolean = false;

  connectedCallback() {
    super.connectedCallback();

    setInterval(async () => {
      GetUserPageFx(
        this.userProfile,
        loginProfileProvider,
        (userProfile: PublicLoginStore_I) => {
          this.userProfile = userProfile;
          this._memberId = userProfile.id;
          this._accountType = userProfile.accountType;
          // console.log({ "~~~~~~~~~~this.userProfile": this.userProfile });
          this.getMemberDashboardReport().then(async res => {
            // console.log({ res });
            this._dashboardReportResponse = res;
            this._dashboardReports = [];
          });
        },
      );
      await this.dashboardReports();
      
      if (this.swiperSelector === undefined) {
        // console.log({ "this.swiperSelector": this.swiperSelector });
        this.swiperSelector = this.querySelector('.swiper');
      } else {
        if (this.swiperSelectorShow === false) {
          this.showSwiper();
          this.swiperSelectorShow = true;
        }
      }
    }, 1000);

    wireframeProvider.setPageId(this.pageId);

    const aMr = new AppMessenger<boolean>('request-reload?');
    aMr.listen(async (reload) => {
      // console.log({ "aM-reload": reload });
      if (reload) {

        await this.refreshPage();
        this.requestUpdate();
      }
    });
  }

  disconnectedCallback() { }
  static styles = [
    css`
   :host { display: block; }
  `
  ];

  render() {
    // console.log({ "this.list": this.list });
    return html`
      <akwaaba-wireframe></akwaaba-wireframe>
      <div slot="wireframecontent" class="main-app-content" scrollbar="app-scrollbar">
        <div class="main-container">
          <!-- Slider main container -->
          <div class="swiper">
            <!-- Additional required wrapper -->
            <div class="swiper-wrapper">
              <!-- Slides -->
              <div class="swiper-slide">
                <alert-card info .header="${html`Sorry!`}" .content="${html`No Ads Found...`}"></alert-card>
              </div>
              ...
            </div>
            <!-- If we need pagination -->
            <div class="swiper-pagination"></div>

            <!-- If we need navigation buttons -->
            <div class="swiper-button-prev"></div>
            <div class="swiper-button-next"></div>

            <!-- If we need scrollbar -->
            <div class="swiper-scrollbar"></div>
          </div>
        </div>
        ${this.findQuestionnaire}
        ${this.getDisplay}
      </div>
    `;
  }

  private get findQuestionnaire() {
    return html`
      <div class="main-container">
        <div class="form-input-container !block text-akwaaba-orange-light">
          <p class="block text-2xl m-0 font-bold">Find Questionnaire.</p>
        </div>
        <form method="post" action="#" class="form">
          <div class="app-container-row flex justify-center !px-0">
            <div class="app-container-col-xl-6 app-container-col-md-10">
              <div class="form-input-container ">
                <mwc-textfield name="special-questionnaire-id" label="Enter Questionnaire Code"
                  outlined type="text" required @change="${this.specialQuestionnaireKeyUp}"
                  @keyup="${this.specialQuestionnaireKeyUp}">
                </mwc-textfield>
              </div>
              <div class="form-input-container">
                <mwc-button label="Submit" name="special-questionnaire-btn" disabled raised @click="${this.findNewQuestionnaire}">
                </mwc-button>
              </div>
            </div>
            <div class="app-container-col-xl-6 app-container-col-md-10"></div>
          </div>
        </form>
      </div>
    `;
  }

  private get getDisplay() {
    if (this._dashboardReports === null) {
      return html`
        <div class="main-container">
          <alert-card info .header="${html`Empty`}" .content="${html`No Data Found`}"></alert-card>
        </div>
      `;
    } else {
      if (this._dashboardReports.length > 0) {
        let networkRes: TemplateResult<1> | symbol = nothing;
        if (this._dashboardReportResponse !== undefined) {
          if (this._dashboardReportResponse['success']) {
            this.dashboardReports();
          } else {
            networkRes = html`
              <alert-card warning .header="${html`Warning`}" .content="${html`${this._dashboardReportResponse['msg']}`}"></alert-card>
            `;
          }
        }
        return html`
          ${networkRes}
          
          <div class="app-container !px-0 ">
            <div class="app-container-row flex justify-start !px-0">
              ${this._dashboardReports.map(report => {
                return html`
                  <div class="app-container-col-md-6">
                    <clickable-card warning description="${report.description}" 
                      icon="${report.icon}" title="${report.title}" 
                      url="${CONSTANTS.URLS.PUBLIC_PAGE_BASE_URL}${report.url}"></clickable-card>
                  </div>
                `
              })}
            </div>
          </div>
        `;
      } else {
        return html`
          <div class="main-container">
            <div class="flex justify-center">
              <mwc-circular-progress indeterminate></mwc-circular-progress>
            </div>
          </div>
        `;
      }
    }
  }

  firstUpdated() {}
  
  private showSwiper() {
    
    // init Swiper:
    const swiper = new Swiper('.swiper', {
      // configure Swiper to use modules
      // cssMode: true,
      modules: [Navigation, Pagination],
      // Optional parameters
      direction: 'horizontal',
      // loop: true,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
    
      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    
      // And if we need scrollbar
      scrollbar: {
        el: '.swiper-scrollbar',
      },
    });
    console.log({swiper});
  
  }

  private specialQuestionnaireKeyUp(e: any) {
    e.preventDefault();
    // @ts-ignore
    const QuestionnaireCode = String(this.querySelector('[name="special-questionnaire-id"]').value),
      QuestionnaireBtn: Button = this.querySelector('[name="special-questionnaire-btn"]');
    if (QuestionnaireCode.length > 0) {
      QuestionnaireBtn.removeAttribute('disabled');
    } else {
      QuestionnaireBtn.setAttribute('disabled', "true");
    }
  }

  private findNewQuestionnaire(e: any) {
    e.preventDefault();
    // @ts-ignore
    const QuestionnaireCode = String(this.querySelector('[name="special-questionnaire-id"]').value);
    window.location.href = CONSTANTS.URLS.PUBLIC_PAGE_BASE_URL + 'questionnaire/' + QuestionnaireCode;
  }

  private async dashboardReports() {
    // console.log({"this._dashboardReports-before": this._dashboardReports});

    this._dashboardReports = await GetDbLoadedAllFx<AppDashboardReport_I>(
      AppDashboardReportStore, this._dashboardReports);
    // console.log({"this._dashboardReports-after": this._dashboardReports});
  }

  private async getMemberDashboardReport() {
    return await GET_MemberDashboardReport(
      this.userProfile,
    );
  }

  private async refreshPage(): Promise<void> {
    await this.getMemberDashboardReport().then(async res => {
      // console.log({ res });
      this._dashboardReportResponse = res;
      this._dashboardReports = [];
      await this.dashboardReports();
    });
    return;
  }

  createRenderRoot() {
    return this;
  }
}
