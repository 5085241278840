import { CONSTANTS } from "@@addons/constants";
import { LoginStore } from "@@addons/database/dixie/login";
import { http } from "@@addons/functions/https/http";
import { PublicLoginStore_I } from "@@addons/interfaces/database/login/public";

export async function GET_AkwaabaMember() {
  const appStore = new LoginStore();
  let __memberId = 0,
    __userProfile: PublicLoginStore_I = {};
  await appStore.getId(async (res) => {
    __memberId = res;
  });
  await appStore.getOne(__memberId, async (response) => {
    __userProfile = response;
  });
  const memberId = __userProfile.id,
    accountType = __userProfile.accountType,
    rememberMe = __userProfile.rememberMe;

  let url = "";
  if (accountType === 1) {
    url = CONSTANTS.URLS.AKWAABA_API_BASE_URL + 'form-membership/individual-account/' + memberId;
  } else {
    url = CONSTANTS.URLS.AKWAABA_API_BASE_URL + 'form-membership/organization-account/' + memberId;
  }
  const HTTP = await http(url, {
    method: "GET",
  }, true, false);

  let output;
  try {
    output = HTTP;
    if (HTTP.success === true) {
      const DATAS: any = HTTP.data;
      console.log({ __userProfile, "HTTP.data": HTTP.data });

      let newDatas: PublicLoginStore_I[] = [];

      DATAS.forEach(async (rawData: any) => {
        let formatRawData: PublicLoginStore_I = appStore.formatRawPublicData(rawData);
        // console.log({ formatRawData });
        formatRawData.rememberMe = rememberMe;

        if (!newDatas.includes(formatRawData)) {
          newDatas.push(formatRawData);
        }
      });
      // console.log({ DATAS, "ds": newDatas });

      await appStore.makePost(newDatas, (postResponse) => {
        // console.log({ postResponse });
      });
    }
  } catch (error) {
    output = error;
    console.error({ error });
  }
  // console.log({ __memberId, __userProfile, memberId, rememberMe, output });

  return output;
}