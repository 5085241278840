import "../.shared.ts";
import "../../../assets/styles/views/wireframe/main.scss";
import { html, css, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import "@@addons/widgets/sidebar/main";
import { wireframeProvider } from "@@addons/mobx-providers/wireframe";
import { MobxLitElement } from '@adobe/lit-mobx';
import "@@addons/widgets/sidebar/right-side-bar";
import { CONSTANTS } from "@@addons/constants";
import "@@addons/widgets/autocomplete_search_box";
import '@material/mwc-button';
import '@material/mwc-icon-button'
import { LoginStore } from "@@addons/database/dixie/login";
import { SaveIfOnline } from "@@addons/database/dixie/offline_posts/save_if_online";
import { loginProfileProvider } from "@@addons/mobx-providers/login";
import { GetNetworkStatus, GetNetworkStatusEvent } from "@@addons/functions/https/network_status";
import Swal from "sweetalert2";
import { AppMessenger } from "@@addons/classes/messenger_channel";
import { PublicAppPages } from "@@addons/constants/public_pages";
import { AppMenuStore } from "@@addons/database/dixie/app_menus";
import { GET_AkwaabaMember } from "@@addons/network/dashboard/get_member";

const LOADING = document.querySelector('[pageLoadingIndicator="on"]');
LOADING.setAttribute('pageLoadingIndicator', 'off');

@customElement("akwaaba-wireframe")
export class AkwaabaWireframe extends MobxLitElement {
  constructor() { super(); }

  smallDevice = window.matchMedia('(max-width: 768px)');
  mediumDevice = window.matchMedia('(max-width: 1280px)');

  loginDb = new LoginStore();

  private _isOnline: boolean = navigator.onLine;

  public set isOnline(value: boolean) {

    let oldVal = this._isOnline;

    this.requestUpdate('isOnline', oldVal);
    this._isOnline = value;
  }

  public get isOnline(): boolean {
    return this._isOnline;
  }

  @property({ type: Boolean })
  noToast: boolean = true;

  async connectedCallback() {
    super.connectedCallback();

    new SaveIfOnline();
    const aM = new AppMessenger<boolean>('online-status');

    this.loginDb.loggedIn();
    this.setLoginStore();

    wireframeProvider.setisSmallDevice(this.smallDevice.matches);
    wireframeProvider.setisMediumDevice(this.mediumDevice.matches);
    // console.log({
    //   "akwaaba-wireframe": "INIT",
    //   "this.smallDevice.matches": this.smallDevice.matches,
    //   "this.mediumDevice.matches": this.mediumDevice.matches,
    // });

    setInterval(() => {

      GetNetworkStatus((status) => {
        // console.log({status, "navigator.onLine": navigator.onLine});

        wireframeProvider.setIsOnline(true);
        aM.post(true);
        this.onlineToast();
      }, (status) => {
        // console.log({status, "navigator.onLine": navigator.onLine});
        this.noToast = false;
        aM.post(false);
        wireframeProvider.setIsOnline(false);
      });
    });
    this.isOnline = true;

    await this.getUserInfo();

    const aMr = new AppMessenger<boolean>('request-reload?');
    aMr.listen(async (reload) => {
      // console.log({ "aM-reload": reload });
      if (reload) {

        await this.refreshPage();
        this.requestUpdate();
      }
    });

  }

  disconnectedCallback() { }

  static get styles() {
    return [
      css`
        :host { display: block; }
      `
    ];
  }

  render() {
    return html`
      <side-bar></side-bar>
      <div class="mdc-drawer-app-content mdc-top-app-bar--fixed-adjust">
        <main class="main-content" id="main-content">
          <header class="mdc-top-app-bar app-bar" id="app-bar">
            <div class="mdc-top-app-bar__row">
              <section class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
                <div class="flex justify-between">
                  <div class="flex">
                    <span>${this.button()}</span>
                    <span id="rightButton-drawer">${this.rightButton()}</span>
                    <autocomplete-search-box></autocomplete-search-box>
                  </div>
                  <div class="flex">
                    <a href="${CONSTANTS.URLS.HOME_PAGE_BASE_URL}system/calendar" class="p-1">
                      <mwc-icon-button icon="calendar_today" class="${this.isCalenderPage}">
                      </mwc-icon-button>
                    </a>
                    <a href="${CONSTANTS.URLS.HOME_PAGE_BASE_URL}system/notifications" class="p-1">
                      <mwc-icon-button icon="notifications" class="${this.isNotificationPage}">
                      </mwc-icon-button>
                    </a>
                  </div>
                </div>
              </section>
            </div>
          </header>
          <slot name="wireframecontent"></slot>
        </main>
        <right-side-bar class="right-side-bar" scrollbar="app-scrollbar"></right-side-bar>
      </div>
    `;
  }

  firstUpdated() {

  }

  private onlineToast() {
    // console.log({"wireframeProvider.isOnline": wireframeProvider.isOnline});
    if (this.noToast === false) {
      if (wireframeProvider.isOnline) {
        this.noToast = true;
        const Toast = Swal.mixin({
          toast: true,
          timer: 120000,
          position: 'top-end',
          timerProgressBar: true,
          showConfirmButton: true,
          showCancelButton: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: 'success',
          title: 'You\'re back online, refresh page?',
          confirmButtonText: 'Refresh',
          preConfirm: (reload) => {
            // location.reload();
            GetNetworkStatusEvent(this);
            this.requestUpdate();
            const aM = new AppMessenger<boolean>('request-reload?');
            aM.post(true);
          }
        });
      } else {
        this.noToast = false;
      }
    }
  }

  private get isCalenderPage() {
    if (window.location.href.indexOf('/system/calendar') > -1) {
      return "active";
    } else {
      return "inactive";
    }
  }

  private get isNotificationPage() {
    if (window.location.href.indexOf('/system/notifications') > -1) {
      return "active";
    } else {
      return "inactive";
    }
  }

  private get isOfflineActivitiesPage() {
    if (window.location.href.indexOf('/system/offline-activities') > -1) {
      return "active";
    } else {
      return "inactive";
    }
  }

  private button(): TemplateResult {
    if (wireframeProvider.isSmallDevice) {
      return html`
        <mwc-icon-button icon="menu" class="mdc-top-app-bar__navigation-icon bg-white fill-current text-gray-500 mr-4">
        </mwc-icon-button>`;
    } else {
      return html``;
    }
  }

  private rightButton(): TemplateResult {
    if ((wireframeProvider.isSmallDevice === false) && (wireframeProvider.isMediumDevice === true)) {
      return html`
        <mwc-icon-button icon="person" class="mdc-top-app-bar__navigation-icon bg-white fill-current text-gray-500 mr-4">
        </mwc-icon-button>`;
    } else {
      return html``;
    }
  }

  private async getMenus() {
    const appMenuStore = new AppMenuStore();
    await appMenuStore.removeAll();
    await appMenuStore.makePost(PublicAppPages, (postResponse) => {
      // console.log({ postResponse });
    });
  }

  private async getUserInfo() {
    await GET_AkwaabaMember();
  }

  private setLoginStore() {
    this.loginDb.getAll((response) => {
      // console.log({response});

      response.forEach(async (res) => {
        // console.log({res});
        loginProfileProvider.setProfile(res);
        // console.log({"loginProfileProvider.profile--2": toJS(loginProfileProvider.profile)});
        await this.getMenus();
      });
    });
  }

  private async refreshPage(): Promise<void> {
    // await this.getMenus();
    await this.getUserInfo();
    return;
  }

  createRenderRoot() {
    return this;
  }
}
