import { URLS } from "@@addons/constants/urls";
import { AppDashboardReportStore } from "@@addons/database/dixie/dashboard";
import { http } from "@@addons/functions/https/http";
import { AppDashboardReport_I } from "@@addons/interfaces/dashboard";
import { PublicLoginStore_I } from "@@addons/interfaces/database/login/public";

export async function GET_MemberDashboardReport(userProfile: PublicLoginStore_I) {
  const appStore = new AppDashboardReportStore();
  const memberId: number = userProfile.id,
    accountType: number = userProfile.accountType;
  
  const url = URLS.AKWAABA_API_BASE_URL + 'dashboard/member/' + memberId + '?accountType=' + accountType,
    HTTP = await http(url, {
      method: "GET",
    }, true, false);

  // console.log({
  //   id,
  //   project,
  //   url,
  //   userProfile
  // });

  let output;
  try {
    output = HTTP;
    if (HTTP.success === true) {
      const DATAS: any = HTTP.data;
      let newDatas: AppDashboardReport_I[] = [];

      DATAS.forEach(async (rawData: any) => {
        let formatRawData = appStore.formatRawData(rawData);
        // console.log({ formatRawData });
        if (!newDatas.includes(formatRawData)) {
          newDatas.push(formatRawData);
        }
      });
      // console.log({ DATAS, "ds": newDatas });
      await appStore.getAll((responses) => {
        responses.forEach(async (response) => {
          const ID = response.id;
          console.log({ID});
          await appStore.removeFrom(ID, (res) => {
            console.log({res});
            
          });
        });
      });
      await appStore.removeAll();
      await appStore.makePost(newDatas, (postResponse) => {
        // console.log({ postResponse });
      });
    }
  } catch (error) {
    output = error;
    console.error({ error });
  }

  return output;
}