import { AppDatabase } from "@@addons/database/dixie";

export async function GetDbLoadedAllFx<Type>(
  Database: new () => AppDatabase<Type>,
  responses?: Type[]): Promise<Type[]> {
  // console.log({ responses });

  if (responses !== null) {
    if (responses.length === 0) {
      let dbResponses: Type[] = [];
      const appStore = new Database();

      // console.log({"appStore": appStore});
      await appStore.getAll(async (gaRes) => {
        // console.log({ gaRes });

        gaRes.forEach(questionnaire => {
          if (!dbResponses.includes(questionnaire)) {
            dbResponses.push(questionnaire);
          }
        });
      });
      if (dbResponses.length === 0) {
        responses = null;
      } else {
        responses = dbResponses;
      }
    }
  }

  return responses;
}